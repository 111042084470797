<template>
  <div class="my-footer">
    <footer class="columns p-5 has-background-black has-text-light">
      <div class="column is-8 is-offset-2">
        <br />
        <nav class="level">
          <div class="level-item column is-4">
            <span class="icon">
              <a target="_blank" rel="noopener" href="https://www.facebook.com/Padelspot-106560744957667"><i
                  class="fab has-text-light fa-facebook"></i></a>
            </span>
            &emsp;
            <span class="icon">
              <a target="_blank" rel="noopener" href="https://www.instagram.com/padelspot.it/">
                <i class="fab has-text-light fa-instagram"></i></a>
            </span>
          </div>
          <small class="level-item has-text-grey column is-4">
            <small>Sviluppato da
              <a href="https://carrettariccardo.dev" target="_blank"
                class="is-inline is-italic has-text-danger">carrettariccardo.dev</a>
            </small>
          </small>
          <small class="level-item column is-4">
            &copy; PadelSpot.it. Tutti i diritti riservati
            <br />
            <a @click.prevent="showCookieBanner = true" class="has-text-danger-dark">Consenso
              Cookie</a>
            <br />
            <a class="has-text-danger-dark"
              href="https://www.privacypolicygenerator.info/live.php?token=83O9RElEfIUVXWwV6xJwEh9qDlEJ2uMH"
              target="_blank">Privacy Policy</a>
          </small>
        </nav>
      </div>
    </footer>

    <BannerCookie v-if="showCookieBanner" v-on:closed="showCookieBanner = false"></BannerCookie>
  </div>
</template>

<script>
import BannerCookie from './BannerCookie.vue';

export default {
  name: "Footer",
  data() {
    return { showCookieBanner: false };
  },
  mounted() {
    this.showCookieBanner = localStorage.getItem(this.$store.getters.acceptCookieKey) == null;
  },
  components: { BannerCookie }
};
</script>

<style scoped>
.my-footer {
  margin-bottom: -1rem;
}
</style>
