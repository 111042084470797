export default {
    INCREMENT_COUNT: 'INCREMENT_COUNT',
    SET_LOGGED_IN: 'SET_LOGGED_IN',
    SET_USER: 'SET_USER',
    SET_USER_INFO: 'SET_USER_INFO',
    SET_CLAIMS: 'SET_CLAIMS',
    SET_REGISTER_STATUS: 'SET_REGISTER_STATUS',
    SET_HAS_INFO_STATUS: 'SET_HAS_INFO_STATUS',
    SET_UNSUBSCRIBE_UPDATE_CLAIMS: 'SET_UNSUBSCRIBE_UPDATE_CLAIMS',

}