import firebase from "../firebase";
import { doc, getDocs, query, limit, addDoc, updateDoc, setDoc, deleteDoc, arrayRemove, arrayUnion, getDoc, orderBy } from "firebase/firestore";
import Users from "./users";

const db = firebase.collection("news");

// {
//   data,
//     titolo,
//     descrizione,
//     immagine,
// }

class NewsService {
  // gets the timestamp of the most future event
  async getLatestTimestamp() {
    const snapshot = await getDocs(query(db, orderBy("timestamp", "desc"), limit(1)));
    return snapshot.size > 0 ? snapshot.docs[0].data().timestamp : null;
  }

  // sets the last timestamp that the user saw (the most recent event timestamp)
  async setLatestTimestamp(userId, latestTimestamp) {
    return Users.update(userId, {
      latestSeenTimestamp: latestTimestamp
    });
  }

  async getAllRaw(maxResults = 15) {
    return await getDocs(query(db, orderBy("data", "desc"), limit(maxResults)));
  }

  async getAll(maxResults = 15) {
    const snapshot = await getDocs(query(db, orderBy("data", "desc"), limit(maxResults)));
    return snapshot.docs.map(doc => doc.data());
  }

  async getNews(id) {
    const snapshot = await getDoc(doc(db, id));
    return snapshot.data();
  }

  async subscribe(idEvent, idUser) {
    return await updateDoc(doc(db, idEvent), { iscritti: arrayUnion(idUser) });
  }

  async unsubscribe(idEvent, idUser) {
    return await updateDoc(doc(db, idEvent), { iscritti: arrayRemove(idUser) });
  }

  async create(center) {
    return await addDoc(db, center);
  }

  async update(id, value) {
    return await setDoc(doc(db, id), value, { merge: true });
  }

  async delete(id) {
    return await deleteDoc(doc(db, id));
  }
}

export default new NewsService();