export default {
    LOGIN: 'login',
    LOGIN_GOOGLE: 'loginGoogle',
    REGISTER: 'register',
    SUBSCRIBE_EMAIL: 'subscribeEmail',
    LOGOUT: 'logout',
    SAVE_PROFILE: 'saveProfile',
    VERIFY_EMAIL: 'verifyEmail',
    UPDATE_PASSWORD: 'updatePassword',
    REAUTHENTICATE_USER: 'reauthenticateUser',
    RESET_PASSWORD: 'resetPassword',
    RELOAD_USER: 'reloadUser',
    UPDATE_USERNAME: 'updateUsername',
    RELOAD_CLAIMS: 'reloadClaims',
    UPDATE_GENERALS: 'updateGenerals',
}