var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"hero",attrs:{"id":"navbar"}},[_c('nav',{staticClass:"navbar py-1",class:{
    'is-dark': _vm.$route.path == '/',
    'is-light': _vm.$route.path != '/',
  },attrs:{"role":"navigation","aria-label":"main navigation"}},[_c('div',{staticClass:"navbar-brand p-1"},[_c('router-link',{staticClass:"navbar-item",class:{
        'has-background-dark': _vm.$route.name == 'Home',
        'has-background-grey-lighter': _vm.$route.name != 'Home',
      },attrs:{"to":"/"}},[(_vm.$route.name == 'Home')?_c('img',{attrs:{"alt":"logo","src":require("../assets/logo_icon_color.png")}}):_vm._e(),(_vm.$route.name != 'Home')?_c('img',{attrs:{"alt":"logo","src":require("../assets/logo_icon_color_dark.png")}}):_vm._e()]),_c('a',{staticClass:"navbar-burger mr-2",class:{
        'has-border-light': _vm.$route.name == 'Home',
        'has-text-light': _vm.$route.name == 'Home',
        'has-border-dark': _vm.$route.name != 'Home',
        'has-text-dark': _vm.$route.name != 'Home',
      },attrs:{"id":"navbarBurger","role":"button","aria-label":"menu","aria-expanded":"false","data-target":"navbarMenu"}},[_c('span',{attrs:{"aria-hidden":"true"}}),_c('span',{attrs:{"aria-hidden":"true"}}),_c('span',{attrs:{"aria-hidden":"true"}})])],1),_c('div',{staticClass:"navbar-menu rounded",attrs:{"id":"navbarMenu"}},[_c('div',{staticClass:"navbar-start my-2"},[_c('router-link',{staticClass:"navbar-item",class:{ 'is-active': _vm.$route.name == 'Home' },attrs:{"to":"/"}},[_vm._v("Home")]),_c('router-link',{staticClass:"navbar-item",class:{ 'is-active': _vm.$route.name == 'Events' },attrs:{"to":"/events"}},[_vm._v("Eventi"),(_vm.$store.getters.isLogged &&
            _vm.$store.getters.isLoadingFirestoreUserData &&
            (!_vm.$store.getters.userInfo.latestSeenTimestamp || _vm.checkDates(_vm.lastNewsTimestamp, _vm.$store.getters.userInfo.latestSeenTimestamp)))?_c('div',{staticClass:"tag is-danger is-rounded ml-2"},[_vm._v(" Novità!")]):_vm._e()]),_c('router-link',{staticClass:"navbar-item",class:{ 'is-active': _vm.$route.name == 'Reserve' },attrs:{"to":"/reserve"}},[_vm._v("Prenota")]),_c('router-link',{staticClass:"navbar-item",class:{ 'is-active': _vm.$route.name == 'PadelStream' },attrs:{"to":"/padelstream"}},[_vm._v("PadelStream "),((new Date().toISOString().slice(0, 10)) < '2024-02-01')?_c('div',{staticClass:"tag is-danger is-rounded ml-2"},[_vm._v("New!")]):_vm._e()]),_c('router-link',{staticClass:"navbar-item",class:{ 'is-active': _vm.$route.name == 'About' },attrs:{"to":"/about"}},[_vm._v("I nostri centri")]),(_vm.$store.getters.isAdmin)?_c('router-link',{staticClass:"navbar-item",class:{
          'is-active': _vm.$route.name == 'Management',
          'has-border-light': _vm.$route.name == 'Home',
          'has-border-dark': _vm.$route.name != 'Home',
        },attrs:{"id":"navbarAdmin","to":"/management"}},[_vm._v("Amministrazione")]):_vm._e()],1),_c('hr',{staticClass:"is-hidden-desktop my-2"}),_c('div',{staticClass:"navbar-end"},[_c('div',{staticClass:"navbar-item"},[(!_vm.$store.getters.isLogged)?_c('div',{staticClass:"columns"},[_c('router-link',{staticClass:"has-text-light column",attrs:{"to":"/register"}},[_c('a',{staticClass:"button is-rounded is-danger"},[_c('strong',[_vm._v("Registrati")])])]),_c('router-link',{staticClass:"has-text-info column",attrs:{"to":"/login"}},[_c('a',{staticClass:"button is-rounded is-outlined",class:{
                  'is-dark': _vm.$route.name == 'Home',
                  'is-dark': _vm.$route.name != 'Home',
                }},[_c('strong',[_vm._v("Accedi")])])])],1):_vm._e()]),(_vm.$store.getters.isLogged)?_c('div',{staticClass:"navbar-item"},[_c('div',{staticClass:"navbar-item has-dropdown is-hoverable",class:{
            'has-border-light': _vm.$route.name == 'Home',
            'has-border-dark': _vm.$route.name != 'Home',
          },attrs:{"id":"navbarProfile"}},[_c('a',{staticClass:"navbar-link",attrs:{"id":"profileButton"}},[(_vm.$store.getters.hasAccess === false)?_c('div',{staticClass:"circle is-inline p-2 mr-2 has-background-danger"}):_vm._e(),(
                _vm.$store.getters.hasAccess &&
                !_vm.$store.getters.user.emailVerified
              )?_c('div',{staticClass:"circle is-inline p-2 mr-2 has-background-warning"}):_vm._e(),_vm._v(" "+_vm._s(_vm.$store.getters.username)+" ")]),_c('div',{staticClass:"navbar-dropdown"},[_c('router-link',{staticClass:"has-text-dark navbar-item",attrs:{"to":"/profile"}},[_vm._v("Profilo")]),_c('hr',{staticClass:"navbar-divider"}),_c('div',{staticClass:"has-text-dark navbar-item",attrs:{"id":"logout-button"},on:{"click":_vm.logout}},[_vm._v(" Esci ")])],1)])]):_vm._e()])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }