<template>
  <div class="fixed-cookie">
    <div class="rounded has-background-white has-shadow-dark m-4 p-4">
      <div class="">
        <h3 class="title is-5">Cookie Policy</h3>
        <p class="mb-4">Utilizziamo i cookie per migliorare il funzionamento del
          sito ed analizzare il traffico web. Vengono raccolti dati di utilizzo
          statistici che verranno utilizzati per il miglioramento della piattaforma.
          Di seguito puoi approvare l'utilizzo dei cookie statistici.
          <br/>
          Utilizzando il nostro sito web, accetti l'utilizzo dei cookie necessari per il suo
          funzionamento.</p>
        <div class="p-2">
          <button class="button is-rounded is-black mr-1" @click="() => declineCookie()">
            Rifiuta
          </button>
          <button class="button is-rounded is-danger ml-1" @click="() => acceptCookie()">
            Chiudi
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import store from "~/store";

export default {
  name: "BannerCookie",
  emits: ['closed'],
  computed: {
    // store() { return store; }
  },
  methods: {
    acceptCookie() {
      localStorage.setItem(this.$store.getters.acceptCookieKey, "true");
      window['ga-disable-UA-216532596-1'] = false;
      this.$emit('closed');
    },
    declineCookie() {
      localStorage.setItem(this.$store.getters.acceptCookieKey, "false");
      window['ga-disable-UA-216532596-1'] = true;
      this.$emit('closed');
    }
  },
  components: {  }
};
</script>

<style scoped>
.fixed-cookie {
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);
  width: 100%;
  max-width: 1200px;
}
</style>